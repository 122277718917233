@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Vazirmatn:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Noto Kufi Arabic", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* style={{
  ...style,
  // backgroundImage: 'linear-gradient(45deg, #e51e27 0%, #681519 100%)',
  // backgroundColor: '#fff',
  backgroundImage: 'conic-gradient(#e80c14, #ff6060, #fcd903, #579bfc, #0753d9)',
                animation: 'gradient 15s ease infinite',
}} */

/* rotate the conic gradient */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* * {
  background-color: transparent!important;
} */

@keyframes backgroundScroll {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}